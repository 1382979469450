Vue.component('form-component', {
    mixins: [Mixins.datafilled],
    data: function () {
        return {
            captchaOn: false,
            hasCaptcha: false,
            captchaExecuted: false,
        }
    },
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    mounted() {
        this.inputFileName();

        let that = this;
        this.$el.addEventListener('click', (e) => {
            that.captchaOn = true;
        });
    },
    methods: {
        submit: function () {
            let that = this;
            let form = this.$el;

            this.$validator.validateAll().then(result => {
                if (result) {
                    form.classList.add('form--loading');
                    if (!this.hasCaptcha || this.captchaExecuted) {
                        this.$root.submitForm(form, (response) => {
                            that.resetCaptcha();
                        });
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                }
            });
        },
        onCaptchaVerified: function () {
            this.captchaExecuted = true;
            this.submit();
        },
        resetCaptcha: function() {
            this.$refs.recaptcha.reset();
            this.captchaExecuted = false;
        },
        inputFileName () {
            let inputs = document.querySelectorAll( '.file-input__input' );
            Array.prototype.forEach.call( inputs, function( input )
            {
                var label	 = input.nextElementSibling,
                    labelVal = label.innerHTML;

                input.addEventListener( 'change', function( e )
                {
                    var fileName = '';
                    if( this.files && this.files.length > 1 )
                        fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
                    else
                        fileName = e.target.value.split( '\\' ).pop();

                    if( fileName )
                        label.innerHTML = fileName;
                    else
                        label.innerHTML = labelVal;
                });
            });
        }
    },
});
