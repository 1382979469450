Vue.component("header-component", {
    data: function () {
        return {
            scrolled: false,
            menuExpanded: false,
            headerInverted: true,
        }
    },
    watch: {
        menuExpanded(newValue) {
            let that = this;

            if(newValue) {
                this.headerInverted = false;
                bodyScrollLock.disableBodyScroll(this.$refs.scrollLock);
            } else {
                if(this.scrolled == true) {
                    that.headerInverted = false;
                } else {
                    that.headerInverted = true;
                }

                bodyScrollLock.enableBodyScroll(this.$refs.scrollLock);
            }
        },

        scrolled(newValue) {
            if(newValue) {
                this.headerInverted = false;
            } else {
                this.headerInverted = true;
            }
        },
    },
    mounted: function () {
        bodyScrollLock.clearAllBodyScrollLocks();

        let that = this;

        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();

        window.addEventListener('resize', function () {
            if(window.innerWidth >= 768) {
                that.menuExpanded = false;
            }
        });
    },
    methods: {
        watchScrollPosition: function () {
            let that = this;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (scrollTop > 50) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        },
    },
});