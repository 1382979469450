Mixins.video = {
    mixins: [Mixins.device],
    props: {
        url: {
            type: String,
            required: true
        },
        poster: {
            type: String,
            required: false
        },
        autoplay: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: function () {
        return {
            fullscreen: false,
            IDidNotFullscreen: false,
        }
    },
    mounted: function () {
        document.addEventListener("fullscreenchange", this.onFullscreenChange);
        document.addEventListener("mozfullscreenchange", this.onFullscreenChange);
        document.addEventListener("webkitfullscreenchange", this.onFullscreenChange);
        document.addEventListener("MSFullscreenChange", this.onFullscreenChange);
    },
    methods: {
        onFullscreenChange: function() {
            const state = document.fullscreenElement || /* Standard syntax */
                document.webkitFullscreenElement || /* Chrome, Safari and Opera syntax */
                document.mozFullScreenElement ||/* Firefox syntax */
                document.msFullscreenElement /* IE/Edge syntax */
            ;

            if (!state) {
                this.fullscreen = false;
                this.$refs.video.removeAttribute("controls");
                this.$refs.video.setAttribute("muted","muted");
                this.$refs.video.muted = true;
            }
        },
        playVideoFullscreen: function() {
            if (this.$refs.video.requestFullscreen) {
                this.$refs.video.requestFullscreen();
                this.restartVideo();
            } else if (this.$refs.video.mozRequestFullScreen) {
                this.$refs.video.mozRequestFullScreen();
                this.restartVideo();
            } else if (this.$refs.video.webkitRequestFullscreen) {
                this.$refs.video.webkitRequestFullscreen();
                this.restartVideo();
            } else if (this.$refs.video.msRequestFullscreen) {
                this.$refs.video.msRequestFullscreen();
                this.restartVideo();
            }
            else {
                this.IDidNotFullscreen = true;
                this.$refs.video.setAttribute("controls","controls");
                this.$refs.video.removeAttribute("muted");
                this.$refs.video.muted = false;
                this.$refs.video.currentTime = 1000;
                this.$refs.video.play();
            }
        },
        restartVideo: function() {
            this.fullscreen = true;
            this.$refs.video.setAttribute("controls","controls");
            this.$refs.video.removeAttribute("muted");
            this.$refs.video.muted = false;
            this.$refs.video.currentTime = 0;
            this.$refs.video.play();
        }
    },
}

